{
    "name": "@crossmint/client-sdk-vanilla-ui",
    "version": "1.1.33",
    "description": "Crossmint client SDK for vanilla JS (no framework required), using web-components",
    "homepage": "https://github.com/Crossmint/crossmint-sdk#readme",
    "bugs": {
        "url": "https://github.com/Crossmint/crossmint-sdk/issues"
    },
    "repository": "https://github.com/Crossmint/crossmint-sdk",
    "license": "Apache-2.0",
    "author": "Paella Inc",
    "sideEffects": false,
    "type": "module",
    "exports": {
        "import": "./dist/index.js",
        "require": "./dist/index.cjs"
    },
    "main": "./dist/index.cjs",
    "module": "./dist/index.js",
    "types": "./dist/index.d.ts",
    "files": ["dist", "LICENSE"],
    "scripts": {
        "build": "tsup src/index.ts --clean --format esm,cjs,iife --outDir ./dist --minify --dts --sourcemap",
        "dev": "tsup src/index.ts --clean --format esm,cjs,iife --outDir ./dist --dts --sourcemap --watch"
    },
    "dependencies": {
        "@crossmint/client-sdk-base": "workspace:*",
        "@crossmint/common-sdk-base": "workspace:*",
        "lit": "2.8.0"
    }
}
